import React, { useEffect } from 'react'
import SideBar from './SideBar'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { useState } from 'react';
import { Search, SearchOutlined } from '@mui/icons-material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import LightModeIcon from '@mui/icons-material/LightMode';
import EmailIcon from '@mui/icons-material/Email';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { toast, Zoom } from 'react-toastify';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import Menu from '@mui/material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function Notifications() {
  let [search, setsearch] = useState('')
  const [notifcations, setnotification] = useState([])
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState('')
  const [Subject, setSubject] = useState('')
  const [type, setType] = useState('')
  const [time, settime] = useState('')
  const theme = useTheme();
  const navigate = useNavigate()
  const ITEM_HEIGHT = 48;
  const [anchorEls, setAnchorEls] = React.useState([]);

  const handleClick = (index, event) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');

  function convertToBase64(file){
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result)
      };
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleFileChange = async(event) => {
    const fileInput = event.target;
    const selectedFile = fileInput.files[0];

    if (selectedFile) {
      const selectedFileName = selectedFile.name;
      setFileName(selectedFileName);
      // const base64 = await convertToBase64(selectedFile);
      // setFile(base64)
      const reader = new FileReader();
      reader.onload = (e) => {
        setFile(e.target.result);
      };
      reader.readAsText(event.target.files[0]);
    }

  };

  console.log(file)

  const handleMenuClose = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };


  const addNotifications = async (e) => {
    e.preventDefault()
    try {
      let notification = await axios.post('https://api.ayurai.in/api/data/add-notifcation', {
        title,
        Subject,
        type,
        time
      })
      if (notification.status === 201) {
        handleClose()
        getnotications()
        toast.success(notification.data, {
          position: "top-center",
          limit: 1,
          transition: Zoom,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    }
    catch (error) {
      toast.error(error.response.data, {
        position: "top-center",
        limit: 1,
        transition: Zoom,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }
  const addEmailNotifications = async (e) => {
    e.preventDefault()
    try {
      let notification = await axios.post('https://api.ayurai.in/api/data/EmailNotification', {
        Subject,
        fileName,
        file
      })
      if (notification.status === 200) {
        handleClose2()
        toast.success(notification.data, {
          position: "top-center",
          limit: 1,
          transition: Zoom,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    }
    catch (error) {
      toast.error(error?.response?.data, {
        position: "top-center",
        limit: 1,
        transition: Zoom,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  let getnotications = async () => {
    try {
      let res = await axios.get('https://api.ayurai.in/api/data/get-notifcations')
      if (res.status === 200) {
        setnotification(res.data)
      }
    }
    catch (error) {
      console.log(error.response.data)
    }
  }
  let handledelete = async (id) => {
    try {
      let res = await axios.delete(`https://api.ayurai.in/api/data/delete-notifcation/${id}`)
      if (res.status === 200) {
        toast.warning(res.data, {
          position: "top-center",
          limit: 1,
          transition: Zoom,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
        getnotications()
      }
    }
    catch (error) {
      console.log(error.response.data)
    }
  }

  useEffect(() => {
    getnotications()
  }, [])

  return <>
    <SideBar />
    <div className="p-4 sm:ml-64">
      <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
        <div className='notification-container'>
          <p>Notifications <NotificationsActiveIcon style={{ fontSize: "30px" }} /></p>
          <div className='Search-bar'>
            <div className='search-box'>
              <SearchOutlined />
              <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

            </div>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button className='add-btn' style={{ backgroundColor: "blue", color: "white", padding: "12px 14px", textTransform: "none" }}
              onClick={handleClickOpen}>Add Notification <NotificationAddIcon /></Button>
            <Button className='add-btn' style={{ backgroundColor: "blue", color: "white", padding: "12px 14px", textTransform: "none" }}
              onClick={handleClickOpen2}>Send Email Notification &nbsp;<EmailIcon /></Button>
          </div>

        </div>
        <div className='notification-list'>
          {notifcations && notifcations.filter((s) => {
            if (search === '') {
              return s
            }
            else if (s.title.toLowerCase().includes(search.toLowerCase()) || s.time.toLowerCase().includes(search.toLowerCase()) || s.Subject.toLowerCase().includes(search.toLowerCase()) ||
              s.type.toLowerCase().includes(search.toLowerCase())) {
              return s
            }
          }).map((e, i) => {
            return <div className='notification-card' key={i}>
              <p id='notification-title'>{e.title}
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    onClick={(event) => handleClick(i, event)}
                    aria-haspopup="true"

                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEls[i]}
                    open={Boolean(anchorEls[i])}
                    onClose={() => handleMenuClose(i)}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                      },
                    }}
                  >
                    <MenuItem onClick={() => {
                      navigate(`/EditNotification/${e._id}`);
                      handleMenuClose(i);
                    }}>
                      <p id='delete-btn'> Edit <EditNotificationsIcon style={{ color: "blue" }} /></p>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handledelete(e._id);
                      handleMenuClose(i);
                    }}>
                      <p id='delete-btn'>Delete <DeleteIcon style={{ color: "red" }} /></p>
                    </MenuItem>



                  </Menu>
                </div></p>
              <p>({e.type})</p>
              <p id='notification-subject'>{e.Subject}</p>

              {/* <p>{e.type}</p>
          <p>{e.time}</p> */}
            </div>
          })
          }
        </div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Add Notification"}
          </DialogTitle>
          <DialogContent className='dialog-content'>
            <div style={{ marginTop: "1rem" }} className='dialog-form'>
              <TextField id="outlined-basic" label="Title" variant="outlined" onChange={(e) => {
                setTitle(e.target.value)
              }} />
              <TextField id="outlined-basic" label="Subject" variant="outlined" onChange={(e) => {
                setSubject(e.target.value)
              }} />
              <select onChange={(e) => {
                setType(e.target.value)
              }}>
                <option>
                  Gut
                </option>
                <option>
                  consultation
                </option>
                <option>
                  Diet
                </option>
              </select>
              <div className='radio-btn'>


                <div>
                  <input type="radio" id="huey" name="drone" value="morning"
                    onClick={(e) => {
                      settime(e.target.value)
                    }}
                  />
                  &nbsp;<label for="huey">morning <LightModeIcon style={{ color: '#FFD700' }} /></label>
                </div>

                <div>
                  <input type="radio" id="dewey" name="drone" value="afternoon" onClick={(e) => {
                    settime(e.target.value)
                  }} />
                  &nbsp;<label for="dewey">afternoon <Brightness7Icon style={{ color: "#FFAE42" }} /></label>
                </div>

                <div>
                  <input type="radio" id="louie" name="drone" value="evening"
                    onClick={(e) => {
                      settime(e.target.value)
                    }} />
                  &nbsp;<label for="louie">evening <NightlightRoundIcon /></label>
                </div>

              </div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={(e) => addNotifications(e)}>
              Submit
            </Button>
            <Button onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={open2}
          onClose={handleClose2}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Add Email Notification"}
          </DialogTitle>
          <DialogContent className='dialog-content'>
            <div style={{ marginTop: "1rem" }} className='dialog-form'>

              <TextField id="outlined-basic" label="Subject" variant="outlined" onChange={(e) => {
                setSubject(e.target.value)
              }} />
              <input type="file" id="htmlFile" name="htmlFile" style={{ display: "none" }} onChange={handleFileChange} accept=".html"></input>
              <label class="custom-button" for="htmlFile" style={{
                display: 'inline-block',
                padding: '10px 15px',
                backgroundColor: '#3498db',
                color: '#fff',
                cursor: 'pointer',
                border: 'none',
                borderRadius: '5px',
                marginRight: '10px',
              }} >Choose File</label>
              <span style={{ marginTop: '10px' }}>{fileName}</span>
            </div>

          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={(e) => addEmailNotifications(e)}>
              Submit
            </Button>
            <Button onClick={handleClose2}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  </>
}

export default Notifications