import React from 'react'
import SideBar from './SideBar'
import Table from '@mui/material/Table';
import { useState, useContext } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { SearchOutlined } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { contextAPI } from './Context';
function DoctorsAppointment() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [Search, setsearch] = useState('')
    const [about, setAbout] = useState('')
    const [open, setOpen] = useState(false);
    const data =useContext(contextAPI)
    const handleClickOpen = (id) => {
        setAbout(id)
        // alert(id)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }
    let Navigate = useNavigate()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <h1 className='Order_title'>Doctor's Appointments</h1>
                <div className='Search-bar'>
                    <div className='search-box'>
                        <SearchOutlined />
                        <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

                    </div>
                </div>
                <TableContainer sx={{ maxHeight: 440 }} className='Table mt-5' >
                    <Table stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>

                                <TableCell className='Table-header'
                                    style={{ minWidth: "100px" }}

                                >
                                   Patient Name
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                     Doctor Name
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    Doctor Speciality
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    Appointment Status
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    Appointment Date
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    More info
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.DoctorAppointment
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((s) => {
                                    if (Search === "") {
                                        return s;
                                    }
                                    else if (s.Data.patient_name.toLowerCase().includes(Search.toLowerCase())||s.Data.doctor_name.toLowerCase().includes(Search.toLowerCase())) {
                                        return s;
                                    }
                                })
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>


                                            <TableCell >
                                                {row.Data.patient_name}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.doctor_name}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.doctor_speciality}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                
                                               
                                            <div style={row.Data.appointment_status === 'cancelled'||row.Data.appointment_status ==='cancelled_time' ? { color: "red", padding: '10px', backgroundColor: "#FFCCCB", borderRadius: '8px' } : row.Data.appointment_status === 'pending' ? { color: "yellow", backgroundColor: '#FFFFE0', borderRadius: '8px', padding: '10px' } : row.Data.appointment_status === 'completed' ? { color: 'blue',backgroundColor: "#89CFF0", borderRadius: '8px', padding: '10px' } : {
                          color: "green", padding: '10px', backgroundColor: "#FFFFE0", borderRadius: '8px'
                        }}>{row.Data.appointment_status}</div>
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.appointment_date}
                                            </TableCell>
                                           
                                            <TableCell align={"center"}>
                                                <Button variant="outlined" onClick={() => handleClickOpen(row._id)}>
                                                    view
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TableFooter align="right">
                        <TablePagination

                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={data.DoctorAppointment.length}

                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableFooter>
                </TableContainer>
                {data.DoctorAppointment.filter((e) => {
        if (e._id.includes(about)) {
          return e
        }
      })
        .map((e) => {

          return <>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                      More information
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <div className='doctor-details'>
                                <div>
                            <img src={e.Data.doctor_photo} style={{width:'8rem',margin:'1rem'}}></img>
                            <p style={{margin:'1rem',width:"8rem",textAlign:"center"}}>{e.Data.doctor_name}</p>
                            </div>
                            <div className='appointment-details'>
                            <p>Appointment Time: <span>{e.Data.appointment_time}</span></p>
                            <p>Consultation Charges: <span>{e.Data.appointment_pay}</span></p>
                            <p>Consultation Mode: <span>{e.Data.consultation_type} call</span></p>
                            <p>Patient id: <span>{e.Data.patient_id} </span></p>
                            <p>Patient Name: <span>{e.Data.patient_name} </span></p>
                            </div>
                            </div>
                           
                        </Typography>
                    </DialogContent>
            

                    {/* <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            Save changes
                        </Button>
                    </DialogActions> */}
                </BootstrapDialog> </>
        })}

            </div>
        </div>
    </>
}
export default DoctorsAppointment