import React, { useState, useContext, useEffect } from 'react'
import SideBar from './SideBar'
import Diversity1Icon from '@mui/icons-material/Diversity1';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from './setAuthToken';
import { CChart, CChartBar } from '@coreui/react-chartjs'
import axios from 'axios';
import { DateRangePicker } from 'react-date-range'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { contextAPI } from './Context';
import Skeleton from '@mui/material/Skeleton';
function Dashboard() {
    let [months, setmonth] = useState([])
    let [filter, setFilter] = useState(false)
    let [Startdate, setStartDate] = useState('')
    let [endDate, setEndDate] = useState('')

    let Handlefilter = () => {
        setFilter(!filter)
    }
    const handleSelect = (date) => {
        console.log(date.selection.endDate)
        setStartDate(date.selection.startDate)
        setEndDate(date.selection.endDate)
    };

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    let navigate = useNavigate()

    let data = useContext(contextAPI)
    console.log(data)

    useEffect(() => {
        if (!sessionStorage.getItem('AccessToken')) {
            navigate('/Login')
        }
        else {
           data.getDoctors()
           data.getDoctorsAppointment()
           data.getAllOrders()
           data.getUsers()
        }

    }, [sessionStorage.getItem('AccessToken')])

    useEffect(() => {
        // window.location.reload(false)
        // setTimeout(() => {
        //     window.location.reload(false);
        // },100);
        data = data
    }, [data])
    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <div className='Main-contents'>
                    {/* <div className='revenue-filter'>
                        <button onClick={Handlefilter}>Apply filter</button>
                        {filter && <DateRangePicker className='date-picker'
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                        />}
                        {/* <select onChange={(e)=>Setrevenue(e.target.value)}>
                        <option value='Monthly'>Last Month</option>
                        <option value='Weekly'>Last Week</option>
                        <option value='Today'>Today</option>
                        <option value='Yesterday'>Yesterday</option>
                        <option value='Quarterly'>Last 90 days</option>
                        <option value='Halfyearly'>Last 6 months</option>
                        <option value='Yearly'>Last 12 months</option>
                    </select> */}
                    {/* </div> */}
                    <div className='Cards'>
                        <div className='card' onClick={() => { navigate('/orders') }}>
                            <div className='Icons' style={{ backgroundColor: "#90EE90" }}>
                                <ShoppingCartIcon style={{ color: "green" }} />
                            </div>

                            <div className='card-details' id='order'>
                                <p>{data.Orders.length > 0 && data.Orders.length}</p>
                                <p>Orders</p>
                            </div>
                        </div>
                        <div className='card' onClick={() => { navigate('/Activeusers') }}>
                            <div className='Icons' style={{ backgroundColor: "#89CFF0" }}>
                                <VerifiedUserIcon style={{ color: "blue" }} />
                            </div>
                            <div className='card-details'>
                                <p id="total">{data.users.length > 0 && data.users.length}</p>
                                <p>App users</p>
                            </div>
                        </div>
                        <div className='card' onClick={() => { navigate('/DoctorAppointment') }}>
                            <div className='Icons' style={{ backgroundColor: "#90EE90" }}>
                                <CalendarMonthIcon style={{ color: "green" }} />
                            </div>
                            <div className='card-details'>
                                <p>{data.DoctorAppointment.length > 0 && data.DoctorAppointment.length}</p>
                                <p>Appointments</p>
                            </div>
                        </div>

                        <div className='card' onClick={() => { navigate('/Doctors') }}>
                            <div className='Icons' style={{ backgroundColor: "#89CFF0" }}>
                                <i class="fa-solid fa-user-doctor" style={{ color: "blue", fontSize: '20px' }} />
                            </div>
                            <div className='card-details'>
                                <p>{data.Doctors.length > 0 && data.Doctors.length}</p>
                                <p>Doctors</p>
                            </div>

                        </div>
                    </div>
                    <div className='Sales_report'>
                        <p>App Reports </p>
                        <div className='charts'>

                            <CChart className='chart'
                                type="bar"
                                data={{
                                    labels: ["Male", 'Female'],
                                    datasets: [
                                        {
                                            label: 'Gender',
                                            backgroundColor: '#f87979',
                                            data: [data.users.filter((e) => {
                                                if (e.Data.gender === 'Male') {
                                                    return e
                                                }
                                            }).map((e) => { return e }).length, data.users.filter((e) => {
                                                if (e.Data.gender === 'Female') {
                                                    return e
                                                }
                                            }).map((e) => { return e }).length],
                                        },
                                    ],
                                }}
                                labels="months"
                            />
                            {/*  <CChart className='chart'
                                type="pie"
                                data={{
                                    labels: ['Tamil Nadu', 'Karnataka', 'Andhra', 'Telagana', 'Kerala'],
                                    datasets: [
                                        {
                                            backgroundColor: ['#0d98ba', '#F7B7A3', 'skyblue', '#90EE90', '#C70039'],
                                            data: [states.TamilNadu, states.Karnataka, states.andhra, states.telugana, states.kerala],
                                        },
                                    ],
                                }}
                            />*/}
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </>
}

export default Dashboard