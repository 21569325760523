import React from 'react'
import SideBar from './SideBar'
import { useState, useEffect } from 'react';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import axios from 'axios';
import { toast, Zoom } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
function EditNotification() {
  let [title, setTitle] = useState('')
  const [Subject, setSubject] = useState('')
  const [type, setType] = useState('')
  const [time, settime] = useState('')
  let navigate =useNavigate()
  let { id } = useParams()
  let getnotications = async () => {
    try {
      let res = await axios.get(`https://api.ayurai.in/api/data/find-notifcation/${id}`)
      if (res.status === 200) {
        setTitle(res.data.title)
        setSubject(res.data.Subject)
        setType(res.data.type)
        settime(res.data.time)
      }
    }
    catch (error) {
      console.log(error.response.data)
    }
  }
  useEffect(() => {
    getnotications()
  }, [])

  let handlesubmit = async (e) => {
    e.preventDefault()
    try {
      let res = await axios.put('https://api.ayurai.in/api/data/edit-notifcation', {
        id,
        title,
        Subject,
        type,
        time
      })
      if(res.status===200){
        navigate(-1)
        toast.info(res.data, {
          position: "top-center",
          limit: 1,
          transition: Zoom,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
    }
    catch (error) {
      toast.error(error.response.data, {
        position: "top-center",
        limit: 1,
        transition: Zoom,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  return <>
    <SideBar />
    <div className="p-4 sm:ml-64">
      <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
        <div className='notification-container'>
          <p>Edit Notification <EditNotificationsIcon style={{ fontSize: "30px" }} /></p>
          <div className='edit-inputs'>
            <input type='text' value={title} onChange={(e) => {
              setTitle(e.target.value)
            }} id='ediTitle'></input>
            <textarea value={Subject} onChange={(e) => { setSubject(e.target.value) }} rows="5" cols="33" id='editSubject'></textarea>
            <select type='text' value={type} onChange={(e) => {
              setType(e.target.value)
            }} id='editType'>
              <option>Gut</option>
              <option>consultation</option>
              <option>Diet</option>
            </select>
            <select type='text' value={time} onChange={(e) => {
              settime(e.target.value)
            }} id='editTime'>
              <option>morning</option>
              <option>afternoon</option>
              <option>evening</option>
            </select>
            <Button id='edit-submit' onClick={(e) => {
              handlesubmit(e)
            }}>Submit</Button>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default EditNotification