import React from 'react'
import SideBar from './SideBar'
import Table from '@mui/material/Table';
import { useState, useContext} from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { SearchOutlined } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

import { contextAPI } from './Context';
function Active_users() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [Search, setsearch] = useState('')
    const data =useContext(contextAPI)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <h1 className='Order_title'>App Users</h1>
                <div className='Search-bar'>
                    <div className='search-box'>
                        <SearchOutlined />
                        <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

                    </div>
                </div>
                <TableContainer sx={{ maxHeight: 440 }} className='Table mt-5' >
                    <Table stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>

                                <TableCell className='Table-header'
                                    style={{ minWidth: "100px" }}

                                >
                                    User Id
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    User Name
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                   Gender
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    Height
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                   Weight
                                </TableCell>
                                <TableCell className='Table-header'
                                    align={"center"}
                                    style={{ minWidth: "100px" }}

                                >
                                    D.O.B
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.users
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((s) => {
                                    if (Search === "") {
                                        return s;
                                    }
                                    else if (s.Data.firstname.toLowerCase().includes(Search.toLowerCase())||s.Data.lastname.toLowerCase().includes(Search.toLowerCase())) {
                                        return s;
                                    }
                                })
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>


                                            <TableCell >
                                                {row.UID}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.firstname} {row.Data.lastname}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.gender}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.height}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                {row.Data.weight}
                                            </TableCell>

                                            <TableCell align={"center"}>
                                              {row.Data.dob}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TableFooter align="right">
                        <TablePagination

                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={data.users.length}

                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableFooter>
                </TableContainer>


            </div>
        </div>
    </>
}
export default Active_users