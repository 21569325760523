import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { setAuthToken } from './setAuthToken';
import { useNavigate } from 'react-router-dom';
export const contextAPI = React.createContext()

function Context({ children }) {
    const [Doctors, setDoctors] = useState([])
    const [Orders, SetOrders] = useState([])
    const [DoctorAppointment, setAppointment] = useState([])
    const [users, setusers] = useState([])

    let renew = async () => {
        try {
            let data = sessionStorage.getItem("User_details")
            let User_details = JSON.parse(data)
            let token = sessionStorage.getItem("refreshToken")
            let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
                email: User_details.Email,
                token: token
            })
            if (res.status === 200) {
                sessionStorage.setItem('AccessToken', res.data.access_token)
                getDoctors()
                getDoctorsAppointment()
                getAllOrders()
                getUsers()

            }
        }
        catch (error) {
            console.log(error)
        }
    }
    let getDoctors = async () => {
        try {
            let Token = sessionStorage.getItem('AccessToken')
            setAuthToken(Token)
            let res = await axios.post(`https://api.ayurai.in/api/data/getallDoctors`)
            if (res.status === 200) {
                setDoctors(res.data)
            }
        }
        catch (error) {
            renew()
        }
    }
    let getDoctorsAppointment = async () => {
        try {
            let Token = sessionStorage.getItem('AccessToken')
            setAuthToken(Token)
            let res = await axios.post(`https://api.ayurai.in/api/data/getTotalappointments`)
            if (res.status === 200) {
                setAppointment(res.data)
            }
        }
        catch (error) {
            renew()
        }
    }

    let getAllOrders = async () => {
        try {
          let Token = sessionStorage.getItem('AccessToken')
          setAuthToken(Token)
          let res = await axios.post(`https://api.ayurai.in/api/data/getallOrders`)
          if (res.status === 200) {
            SetOrders(res.data)
          }
        }
        catch (error) {
          renew()
        }
      }

      let getUsers = async () => {
        try {
            let Token = sessionStorage.getItem('AccessToken')
            setAuthToken(Token)
            let res = await axios.post(`https://api.ayurai.in/api/data/getAllprofile`)
            if (res.status === 200) {
                setusers(res.data)
            }
        }
        catch (error) {
            renew()
        }
    }
    // let navigate = useNavigate()
    useEffect(() => {
        let Token = sessionStorage.getItem('AccessToken')
        setAuthToken(Token)
        getDoctors()
        getDoctorsAppointment()
        getAllOrders()
        getUsers()
        
    
    }, [])
    

    return <contextAPI.Provider value={{Doctors,DoctorAppointment,Orders,users,getDoctors,getDoctorsAppointment,getAllOrders,getUsers}}>
        {children}
    </contextAPI.Provider>
}
export default Context;