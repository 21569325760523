import React from 'react'
import SideBar from './SideBar'
import { useContext } from 'react'
import PlaceIcon from '@mui/icons-material/Place';
import { useParams } from 'react-router-dom'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Success from './success.png'
import cancel from './cancelled (1).png'
import { contextAPI } from './Context';
function Cartitems() {
    let { id } = useParams()
    const data =useContext(contextAPI)
    const order=data.Orders.filter((e)=>{
        if(e.Data.orderid.includes(id)){
             return e
        }
    }).map((e)=>{
        return e
    })
    console.log(order)
    return <>

        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <div className='Cart-page'>

                    <div className='Customer-details'>
                        <p>Name : <span>{order[0].Data.partners.customer[0].billing.name}</span></p>
                        <p>Email : <span>{order[0].Data.email}</span></p>
                        <p>Mobile Number :<span>{order[0].Data.phone}</span></p>
                        <p>User id : <span>{order[0].Data.userid}</span></p>
                        <p>Order id : <span>{order[0].Data.orderid}</span></p>
                        <p>Placed on : <span>{order[0].Data.dateandtime}</span></p>
                        <p>Address <PlaceIcon style={{ fontSize: 'medium', color: "red" }} /> : <span>{order[0].Data.address}</span></p>
                        <p>Total : <span>{order[0].Data.total}</span></p>
                    </div> 
                    <div className='Order_Status' style={order[0].Data.orderstatus === 'cancelled' ? { backgroundColor: "#FFCCCB" } : order[0].Data.orderstatus === 'received' ? { backgroundColor: '#FFFFE0' } : order[0].Data.orderstatus === 'delivered' ? { backgroundColor: 'lightcyan' } : { backgroundColor: "#89CFF0" }}>
                        <div className='Order-status-element' >
                            <p>Order Status : <span>{order[0].Data.orderstatus}</span></p>
                            {order[0].Data.orderstatus === "delivered" ? <img src={Success}></img> : order[0].Data.orderstatus === 'cancelled' ? <img src={cancel}></img> : <></>}
                        </div>
                    </div>
                    <div className='Cart-items'>
                        <p>Cart items</p>
                        <TableContainer sx={{ maxHeight: 440 }} className='Table mt-5' >
                            <Table stickyHeader aria-label="sticky table" >
                                <TableHead>
                                    <TableRow>

                                        <TableCell className='Table-header'
                                            style={{ minWidth: "100px" }}

                                        >
                                            Product
                                        </TableCell>
                                        <TableCell className='Table-header'
                                            align={"center"}
                                            style={{ minWidth: "100px" }}

                                        >
                                            Quantity
                                        </TableCell>
                                        <TableCell className='Table-header'
                                            align={"center"}
                                            style={{ minWidth: "100px" }}

                                        >
                                            Price
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(order[0].Data.cartitems).map(([type, make]) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={type}>


                                                <TableCell >
                                                    <div className='Product-details'>
                                                        <img src={make.image} ></img>
                                                        <div>
                                                            <p>{make.name}</p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {make.qty}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {make.price}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            {/* <TableFooter align="right">
                            <TablePagination

                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={Orders.length}

                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableFooter> */}
                        </TableContainer> 
                    </div> 

                              
                </div>
            </div>
        </div>
    </>
}

export default Cartitems