import logo from './logo.svg';
import './App.css';
import Login from './Compontents/Login';
import Dashboard from './Compontents/Dashboard';
import Doctors from './Compontents/Doctors';
import Orders from './Compontents/Orders';
import Active_users from './Compontents/Active_user';
import Notifications from './Compontents/Notifications';
import DoctorsAppointment from './Compontents/DoctorsAppointment';
import EditNotification from './Compontents/EditNotification';
import Cartitems from './Compontents/Cartitems';
import { ToastContainer} from 'react-toastify';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
function App() {
  return <>
    <BrowserRouter>
   <ToastContainer/>
   <Routes>
    <Route path='/' element={<Dashboard/>}/>
    <Route path='/orders' element={<Orders/>}/>
    <Route path='/Doctors' element={<Doctors/>}/>
    <Route path='/Cart/:id' element={<Cartitems/>}/>
    <Route path='/Notifications' element={<Notifications/>}/>
    <Route path='/Login' element={<Login/>}/>
    <Route path='/Activeusers' element={<Active_users/>}/>
    <Route path='/EditNotification/:id' element={<EditNotification/>}/>
    <Route path='/DoctorAppointment' element={<DoctorsAppointment/>}/>
   </Routes>
   </BrowserRouter>
  </>
}

export default App;
