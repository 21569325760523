import React from 'react'
import SideBar from './SideBar'
import Table from '@mui/material/Table';
import { useState, useContext } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { SearchOutlined } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { setAuthToken } from './setAuthToken';
import { useNavigate } from 'react-router-dom';
import { contextAPI } from './Context';
function Orders() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companyid, setcomapnyid] = useState(1001)
  const [Search, setsearch] = useState('');
  const data = useContext(contextAPI)
  let Navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return <>
    <SideBar />
    <div className="p-4 sm:ml-64">
      <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
        <h1 className='Order_title'>Orders</h1>
        <div className='Search-bar'>
          <div className='search-box'>
            <SearchOutlined />
            <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

          </div>
        </div>

        <p style={{ margin: "1rem" }}>Company ID</p>
        <select onChange={(e) => {
          setcomapnyid(e.target.value)
        }} style={{ margin: "1rem", marginTop: "5px" }} >
          <option value={1001}>1001</option>
          <option value={1002}>1002</option>
          <option value={1003}>1003</option>
          <option value={1004}>1004</option>
          <option value={1005}>1005</option>
          <option value={1006}>1006</option>
        </select>
        <TableContainer sx={{ maxHeight: 440 }} className='Table mt-5' >
          <Table stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>

                <TableCell className='Table-header'
                  style={{ minWidth: "100px" }}

                >
                  Order id
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Phone
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Email
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Date
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Order Status
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Total
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.Orders.filter((row) => {
                if (row.Data.companyid === companyid) {
                  return row
                }
              }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((s) => {
                if (Search === "") {
                  return s;
                }
                else if (s.Data.orderid.toLowerCase().includes(Search.toLowerCase())) {
                  return s;
                }
              }).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell >
                      {row.Data.orderid}
                    </TableCell>
                    <TableCell align={"center"}>
                      {row.Data.phone}
                    </TableCell>
                    <TableCell align={"center"}>
                      {row.Data.email}
                    </TableCell>
                    <TableCell align={"center"}>
                      {row.Data.dateandtime}
                    </TableCell>
                    <TableCell align={"center"}>

                      <div style={row.Data.orderstatus === 'cancelled' ? { color: "red", padding: '10px', backgroundColor: "#FFCCCB", borderRadius: '8px' } : row.Data.orderstatus === 'received' ? { color: "yellow", backgroundColor: '#FFFFE0', borderRadius: '8px', padding: '10px' } : row.Data.orderstatus === 'delivered' ? { color: 'green', backgroundColor: 'lightcyan', borderRadius: '8px', padding: '10px' } : {
                        color: "green", padding: '10px', backgroundColor: "#FFFFE0", borderRadius: '8px'
                      }}>{row.Data.orderstatus}</div>
                    </TableCell>
                    <TableCell>
                      <p style={{ textAlign: "center" }}>{row.Data.total}</p>
                    </TableCell>
                    <TableCell align={"center"}>
                      <div style={{ padding: "2px", color: "white", backgroundColor: "black", borderRadius: "6px", cursor: "pointer" }} onClick={() => { Navigate(`/Cart/${row.Data.orderid}`) }}>View</div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TableFooter align="right">
            <TablePagination

              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.Orders.length}

              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        </TableContainer>
      </div>
    </div>
  </>
}

export default Orders